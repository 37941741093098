export const white = "#fff";
export const whiteAlpha25 = "rgba(255, 255, 255, 0.25)"
export const whiteAlpha90 = "rgba(255, 255, 255, 0.9)";

export const black = "#000";
export const blackAlpha10 = "rgba(0, 0, 0, 0.1)";
export const blackAlpha20 = "rgba(0, 0, 0, 0.2)";
export const searchInput = "#292C2F";


export const lightGray = "#F7F7F7";
export const lightGray2 = "#D8D8D8";
export const lightGray3 = "#F3F3F3";
export const lightGray4 = "#FFFFFF";
export const lightGray5 = "#F5F3F1";
export const lightGray6 = "#F2F2F2";
export const lightGray7 = "#E0E0E0";
export const mediumGray = "#939393";
export const mediumGray2 = "#EAEAEA";
export const darkGray = "#5A5A5A;";
export const darkGray2 = "#C8C8C8";
export const darkGray3 = "#34712B";
export const darkGray4 = "#979797";
export const darkGray5 = "#5D5D5D;";
export const darkGray6 = "#A6AFC1;";
export const darkGray7 = "#676E75";
export const darkestGray = "#2C2C2C";
export const borderBackgroundGray = "#A3A8Ac";
export const inputFieldGray = "#F8F3F3";

export const lightYellow = "#f6f5ee";
export const lightYellow1 = "#D6CCBF";
export const mediumYellow = "#ffde6c";
export const mediumYellow1 = "#BB965D";
export const mediumYellowAlpha20 = "rgba(255, 222, 108, 0.2)";
export const mediumYellowAlpha30 = "rgba(255, 222, 108, 0.3)";
export const darkYellow = "#f1c100";
export const darkYellow1 = "#BB965D";

export const lightGreen = "#dbeae3";
export const mediumGreen = "#54944A";
export const darkGreen = "#235A1B";
export const darkestGreen = "#34712B";
export const darkRedAlpha80 = "rgba(38, 75, 68, 0.8)";

export const blue1 = "#337AB7";
export const lightBlue = "#e7f4fe";
export const lightBlue2 = "#e8f8ff";
export const lightBlue3 = "#E6F2FB";
export const lightBlue4 = "#D0E4F4";
export const lightBlue5 = "#60B9FF"
export const lightBlue6 = "#ECF7FF"
export const lightBlueAlpha40 = "rgba(214, 221, 224, 0.4)";
export const mediumBlue = "#4ea4cd";
export const mediumBlue2 = "#0e8ff1";
export const mediumBlue3 = "#D0E4F4";
export const mediumBlue4 = "#55A7E7";
// export const borderBackgroundGray = "#1F3E7A"; 
export const darkBlue2 = "#0B6FBE";
export const darkBlue3 = "#2A4A8B";
export const darkBlue4 = "#013156";
export const darkBlue5 = "#043D6A";
export const darkestBlue = "#1a3250";
export const darkestBlueAlpha30 = "rgba(26, 50, 80, 0.3)";
export const darkestBlueAlpha40 = "rgba(26, 50, 80, 0.4)";
export const darkestBlueAlpha50 = "rgba(26, 50, 80, 0.5)";
export const darkestBlueAlpha80 = "rgba(26, 50, 80, 0.8)";
export const blueGray = "#CCCCCC";

export const red = "#C62828";
export const mediumRed = "#790000";
export const darkRed = "#660000";

export const lightOrange = "#EEC584";
export const mediumOrange = "#F29018";
export const mediumOrange2 = "#FF5529";
export const darkOrange = "#675459";


export const tableText = "#2C2C2C";
export const tableBorder = "#C8C8C8";
export const mainBG = "#999999";

export const transparent = "transparent";